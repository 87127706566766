import React from 'react';
import PropTypes from 'prop-types';

import { toRupiah } from '../../../helpers';

const PriceItem = ({ label, value }) => (
  <div className="d-flex align-items-center">
    <p style={{ width: 120 }} className="mb-0">{label}</p>
    <p className="mb-0">{toRupiah(value)}</p>
  </div>
);

PriceItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default PriceItem;
