import React from 'react';
import Lottie from 'react-lottie';

import { loadingLottie } from '../../assets';

const LoadingAnimation = () => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loadingLottie,
      }}
      style={{
        height: 120,
      }}
    />
    <p className="mt-4">Sedang mencari data...</p>
  </div>
);

export default LoadingAnimation;
