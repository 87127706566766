import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_METHOD_LIST } from '../../../helpers';

const PaymentGuide = ({ paymentType }) => {
  const paymentSelected = PAYMENT_METHOD_LIST.find((payment) => payment.code === paymentType);

  if (!paymentSelected || !paymentSelected.guides) return null;

  return (
    <>
      <p className="mb-2 font-weight-bold mt-3">Panduan Pembayaran</p>
      {paymentSelected.guides.map((guides) => (
        <Fragment key={guides.title}>
          <p className="mb-0">{guides.title}</p>
          <ul>
            {guides.content.map((content) => (
              <li key={content}>{content}</li>
            ))}
          </ul>
        </Fragment>
      ))}
    </>
  );
};

PaymentGuide.propTypes = {
  paymentType: PropTypes.string.isRequired,
};

export default PaymentGuide;
