import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

const CountdownExpired = ({ paymentExpiry, onComplete }) => (
  <p className="text-danger mb-2">
    Pembayaran kadaluarsa dalam
    {' '}
    <Countdown
      daysInHours
      date={new Date(paymentExpiry)}
      onComplete={onComplete}
    />
  </p>
);

CountdownExpired.propTypes = {
  paymentExpiry: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default CountdownExpired;
