const toRupiah = (number) => {
  const convered = new Intl.NumberFormat('id-ID', {
    currency: 'IDR',
  }).format(number);
  return `Rp.${convered}`;
};

export {
  toRupiah,
};
