import axios from 'axios';

const configApi = async ({
  endpoint, method = 'get', payload = null, params = null, headers = null,
}) => {
  const headerOption = {
    'Content-Type': payload instanceof FormData ? 'multipart/form-data' : 'application/json',
  };
  if (headers) {
    Object.assign(headerOption, headers);
  }
  const serviceURL = process.env.REACT_APP_SERVICE_URL_API;
  const url = `${serviceURL}${endpoint}`;
  const options = {
    url,
    method,
    headers: headerOption,
  };
  if (payload) {
    options.data = payload;
  } else if (params) {
    options.params = params;
  }
  try {
    const response = await axios(options);
    if (!response.data.success) throw new Error(response.data.message);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error.response.data);
  }
};

export default configApi;
