import {
  bcaLogoImage,
  bniLogoImage,
  briLogoImage,
  briTransferLogoImage,
  mandiriLogoImage,
  otherBankImage,
  qrisLogoImage,
} from '../assets';

export const PAYMENT_METHOD_LIST = [
  {
    code: 'bni_va',
    name: 'BNI Virtual Account',
    image: bniLogoImage,
    guides: [
      {
        title: 'ATM BNI',
        content: [
          'Pilih menu lain pada menu utama.',
          'Pilih transfer.',
          'Pilih ke rekening BNI.',
          'Masukkan nomor rekening pembayaran.',
          'Masukkan jumlah yang akan dibayar, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
      {
        title: 'Internet Banking',
        content: [
          'Pilih transaksi, lalu info & administrasi transfer.',
          'Pilih atur rekening tujuan.',
          'Masukkan informasi rekening, lalu konfirmasi.',
          'Pilih transfer, lalu transfer ke rekening BNI.',
          'Masukkan detail pembayaran, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
      {
        title: 'Mobile Banking',
        content: [
          'Pilih transfer.',
          'Pilih virtual account billing.',
          'Pilih rekening debit yang akan digunakan.',
          'Masukkan nomor virtual account, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
    ],
  },
  {
    code: 'bri_va',
    name: 'BRI Virtual Account',
    image: briLogoImage,
    guides: [
      {
        title: 'ATM BRI',
        content: [
          'Pilih transaksi lainnya pada menu utama.',
          'Pilih pembayaran.',
          'Pilih lainnya.',
          'Pilih BRIVA.',
          'Masukkan nomor BRIVA, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
      {
        title: 'Internet Banking',
        content: [
          'Pilih pembayaran & pembelian.',
          'Pilih BRIVA.',
          'Masukkan nomor BRIVA, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
      {
        title: 'BRImo',
        content: [
          'Pilih pembayaran.',
          'Pilih BRIVA.',
          'Masukkan nomor BRIVA, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
    ],
  },
  {
    code: 'echannel',
    name: 'Mandiri Bill Payment',
    image: mandiriLogoImage,
    guides: [
      {
        title: 'ATM Mandiri',
        content: [
          'Pilih Bayar/Beli pada menu utama.',
          'Pilih Lainnya.',
          'Pilih Multi Payment.',
          'Masukkan kode perusahaan 70012.',
          'Masukkan nomor virtual account, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
      {
        title: 'Internet Banking',
        content: [
          'Pilih Bayar pada menu utama.',
          'Pilih Multi Payment.',
          'Pilih Dari rekening.',
          'Pilih Midtrans di bagian Penyedia jasa',
          'Masukkan nomor virtual account, lalu konfirmasi.',
          'Pembayaran selesai.',
        ],
      },
    ],
  },
  {
    code: 'other_va',
    name: 'Bank Lain',
    description: 'Selain bank yang ada diatas',
    image: otherBankImage,
    guides: [
      {
        title: 'Prima',
        content: [
          'Pilih transaksi lainnya pada menu utama.',
          'Pilih transfer.',
          'Pilih rekening bank lain.',
          'Masukkan 009 sebagai kode bank.',
          'Masukkan jumlah yang akan dibayar.',
          'Masukkan nomor rekening pembayaran, lalu konfirmasi.',
          'Pembayaran berhasil.',
        ],
      },
      {
        title: 'ATM Bersama',
        content: [
          'Pilih transaksi lainnya pada menu utama.',
          'Pilih transfer.',
          'Pilih antar bank online',
          'Masukkan 009 sebagai kode bank.',
          'Masukkan jumlah yang akan dibayar.',
          'Masukkan nomor rekening pembayaran, lalu konfirmasi.',
          'Pembayaran berhasil.',
        ],
      },
      {
        title: 'Alto',
        content: [
          'Pilih transaksi lainnya pada menu utama.',
          'Pilih transfer.',
          'Pilih rekening bank lain.',
          'Masukkan 009 sebagai kode bank.',
          'Masukkan jumlah yang akan dibayar.',
          'Masukkan nomor rekening pembayaran, lalu konfirmasi.',
          'Pembayaran berhasil.',
        ],
      },
    ],
  },
  {
    code: 'gopay',
    name: 'QRIS',
    description: 'Scan menggunakan Gopay, OVO, Dana, ShopeePay, BCA Mobile, dll.',
    image: qrisLogoImage,
  },
];

export const STATUS_ORDER = {
  NEW: 'new',
  UNPAID: 'unpaid',
  PAID: 'paid',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const PAYMENT_OPTIONS = {
  MANUAL: 'manual',
  MIDTRANS: 'midtrans',
};

export const TRANSFER_BANK_LIST = [
  {
    code: 'BCA',
    image: bcaLogoImage,
  },
  {
    code: 'BNI',
    image: bniLogoImage,
  },
  {
    code: 'BRI',
    image: briTransferLogoImage,
  },
  {
    code: 'Mandiri',
    image: mandiriLogoImage,
  },
];

export const MIDTRANS_SNAP_URL_SANDBOX = 'https://app.sandbox.midtrans.com/snap/snap.js';

export const MIDTRANS_SMAP_URL_PRODUCTION = 'https://app.midtrans.com/snap/snap.js';
