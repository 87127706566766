import React from 'react';
import PropTypes from 'prop-types';

import { useAppContext } from '../../../hooks';
import { PAYMENT_OPTIONS } from '../../../helpers';

const Notes = ({ paymentOption }) => {
  const { setting } = useAppContext();

  if (paymentOption === PAYMENT_OPTIONS.MANUAL) {
    return (
      <>
        <hr />
        <p className="font-weight-medium">Catatan:</p>
        <ul>
          <li>Setelah pembayaran akan aktif otomatis, verifikasi pesanan Anda akan kami proses dalam 60 menit dan selambat-lambatnya 1x24 jam.</li>
          <li>Pembayaran diatas jam 21.00 WIB akan di proses hari berikutnya.</li>
          <li>Data pembelian dan petunjuk pembayaran juga sudah kami kirim ke email Anda, silakan cek email dari kami di inbox, promotion, dan atau di folder Spam.</li>
          <li>{`Jika dalam 1 jam belum aktif, silahkan silakan hubungi kami melalui : ${setting.businessWhatsApp}`}</li>
        </ul>
      </>
    );
  }

  return (
    <>
      <hr />
      <p className="font-weight-medium">Catatan:</p>
      <ul>
        <li>Data pembelian dan petunjuk pembayaran sudah kami kirim ke email Anda, silakan cek email di inbox, promotion, atau di folder spam.</li>
        <li>Pembayaran diproses secara otomatis sehingga Anda tidak perlu melakukan konfirmasi pembayaran kepada kami.</li>
        <li>Pesanan Anda akan diproses dalam waktu maksimal 5 menit.</li>
        <li>{`Jika sudah lebih dari 5 menit akan tetapi tidak ada email lanjutan mengenai akun Anda, silakan hubungi kami melalui nomor whatsapp ${setting.businessWhatsApp}`}</li>
      </ul>
    </>
  );
};

Notes.propTypes = {
  paymentOption: PropTypes.string.isRequired,
};

export default Notes;
