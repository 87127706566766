import configApi from '../configApi';

const getOrder = ({ id, invoice }) => configApi({ endpoint: `checkout/orders/${id}/${invoice}` });
const getClientOrder = ({ clientId, token }) => configApi({ endpoint: `checkout/orders/client-order?client_id=${clientId}`, headers: { token } });
const getAffiliateInfo = ({ code }) => configApi({ endpoint: `checkout/orders/affiliate-info?code=${code}` });
const createOrder = (payload) => configApi({
  endpoint: 'checkout/orders', method: 'post', payload, headers: { token: payload.token },
});

const endpoint = {
  getOrder,
  getClientOrder,
  getAffiliateInfo,
  createOrder,
};

export default endpoint;
