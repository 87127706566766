import React, { useEffect } from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ProductInfo from './components/ProductInfo';
import FormOrder from './components/FormOrder';
import PriceInfo from './components/PriceInfo';
import PaymentMethod from './components/PaymentMethod';
import productEndpoint from '../../config/service/endpoint/product';
import orderEndpoint from '../../config/service/endpoint/order';
import { secureImage } from '../../assets';
import { generateRandomXToY, MIDTRANS_SMAP_URL_PRODUCTION, MIDTRANS_SNAP_URL_SANDBOX } from '../../helpers';
import { useAppContext } from '../../hooks';
import {
  Button, ErrorMessage, HeaderPaper, LoadingAnimation,
} from '../../components';

const CheckoutPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const tokenParams = searchParams.get('token') || null;
  const clientIdParams = searchParams.get('client_id') || null;
  const affParams = searchParams.get('aff') || null;

  const { setting, state } = useAppContext();

  const { data, isLoading, error } = useQuery('products', () => productEndpoint.getBySlug(params.slug));

  const { data: clientData } = useQuery('client-order', () => orderEndpoint.getClientOrder({ clientId: clientIdParams, token: `Bearer ${tokenParams}` }), {
    enabled: !!(tokenParams && clientIdParams),
    retry: false,
    onError: () => navigate(`/${params.slug}` || '/'),
  });

  const { mutateAsync: createOrderMutate, isLoading: createOrderLoading } = useMutation(orderEndpoint.createOrder, {
    onError: (_error) => toast.error(_error.message),
    onSuccess: (_data) => {
      const { data: resData } = _data;
      navigate(`/thank-you/${resData.id}/${resData.invoice}`);
    },
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = setting.midtransEnvironment === 'production' ? MIDTRANS_SMAP_URL_PRODUCTION : MIDTRANS_SNAP_URL_SANDBOX;
    script.setAttribute('data-client-key', setting.midtransClientKey);
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      whatsapp: '',
      email: '',
      password: '',
      uniqCode: 0,
      paymentType: '',
      affiliateCode: '',
    },
    validationSchema: yup.object({
      name: yup.string().trim().required('Nama harus diisi.').max(50, 'Nama maksimal 50 karakter.'),
      whatsapp: yup.number().typeError('No WhatsApp harus berupa angka.').required('No WhatsApp harus diisi.').test('len', 'No WhatsApp maksimal 15 karakter', (val) => val.toString().length < 15),
      email: yup.string().trim().required('Email harus diisi.').max(50, 'Email maksimal 50 karakter.'),
      password: yup.string().trim().required('Password harus diisi.').min(5, 'Password minimal 5 karakter.'),
      ...(setting.isPaymentMidtrans && ({
        paymentType: yup.string().trim().required('Pilih metode pembayaran terlebih dahulu.'),
      })),
    }),
    onSubmit: async (values) => {
      const res = await createOrderMutate({
        product_id: data.data.id,
        name: values.name,
        whatsapp: values.whatsapp,
        email: values.email,
        password: values.password,
        uniq_code: values.uniqCode,
        payment_type: values.paymentType,
        client_id: clientData?.data?.id,
        token: `Bearer ${tokenParams}`,
        affiliate_code: values.affiliateCode,
        is_payment_midtrans: setting.isPaymentMidtrans,
      });
      const snapToken = res?.data?.midtransRes?.token;
      window.snap.pay(snapToken);
    },
  });

  const { data: affiliateInfoData } = useQuery('affiliate-info', () => orderEndpoint.getAffiliateInfo({ code: formik.values.affiliateCode }), {
    enabled: !!formik.values.affiliateCode,
    retry: false,
  });

  useEffect(() => {
    if (affParams) {
      sessionStorage.setItem('aff', affParams);
      formik.setFieldValue('affiliateCode', affParams);
      setSearchParams((_params) => {
        _params.delete('aff');
        return _params;
      });
    }
    formik.setFieldValue('affiliateCode', sessionStorage.getItem('aff') || '');
  }, [affParams]);

  useEffect(() => {
    if (clientData?.data) {
      const { name, whatsapp, email } = clientData.data;
      formik.setValues({
        ...formik.values,
        name,
        whatsapp,
        email,
        password: 'Hayo ngapain ngintip-intip...',
      });
    }
  }, [clientData]);

  useEffect(() => {
    if (setting.paymentUniqCode && data?.data?.payment_plan !== 7) {
      formik.setFieldValue('uniqCode', generateRandomXToY(100, 999));
    }
  }, [setting]);

  if (isLoading || state.settingLoading) return <LoadingAnimation />;

  if (error || state.settingError) return <ErrorMessage message={error.message} />;

  const { data: productData } = data;

  return (
    <>
      <Helmet>
        <title>{`Checkout - ${setting.businessName}`}</title>
      </Helmet>
      <div className="account-pages pt-sm-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 mb-4">
              <HeaderPaper />
              <ProductInfo
                image={productData.image}
                productName={productData.product_name}
                price={productData.price}
              />
              <div className="d-flex justify-content-between mt-5 pb-1">
                <h6>FORM PEMESANAN</h6>
                {tokenParams && clientIdParams ? (
                  <p className="font-weight-bold mb-0">
                    Bukan akun anda?
                    {' '}
                    <a href={`/${params.slug}`} className="font-weight-bold text-success">
                      Logout
                    </a>
                  </p>
                ) : (
                  <p className="font-weight-bold mb-0">
                    Sudah punya akun?
                    {' '}
                    <a
                      href={`${process.env.REACT_APP_DASHBOARD_URL}?source=checkout&slug=${params.slug}`}
                      className="font-weight-bold text-success"
                    >
                      Login
                    </a>
                  </p>
                )}
              </div>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <div className="card">
                    <div className="card-body p-4">
                      <FormOrder
                        values={formik.values}
                        errors={formik.errors}
                        touched={formik.touched}
                        isReOrder={!!clientData}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  {setting.isPaymentMidtrans && (
                    <PaymentMethod
                      selected={formik.values.paymentType}
                      error={formik.touched.paymentType && formik.errors.paymentType}
                      onChange={(id) => formik.setFieldValue('paymentType', id)}
                    />
                  )}
                  <div className="d-flex justify-content-between mt-5 pb-1">
                    <h6>RINGKASAN PEMBAYARAN</h6>
                  </div>
                  <div className="card">
                    <div className="card-body p-4">
                      <PriceInfo
                        subtotal={productData.price}
                        uniqCode={formik.values.uniqCode}
                        total={productData.price + formik.values.uniqCode}
                      />
                      <Button
                        type="submit"
                        size="lg"
                        className="w-100 mt-4"
                        isLoading={createOrderLoading}
                      >
                        PESAN SEKARANG
                      </Button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
              {affiliateInfoData && affiliateInfoData.data && (
                <p className="text-center">
                  Affiliasi Oleh
                  {' '}
                  <strong>{affiliateInfoData.data.name}</strong>
                </p>
              )}
              <div className="d-flex justify-content-center my-4">
                <img src={secureImage} width="230" alt="secure" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;
