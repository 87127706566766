import React from 'react';
import PropTypes from 'prop-types';

import PriceItem from './PriceItem';

const PriceInfo = ({ subtotal, uniqCode, total }) => (
  <div className="bg-light px-4 py-3 rounded d-flex flex-column" style={{ gap: 10 }}>
    <PriceItem label="Subtotal" value={subtotal} />
    {!!uniqCode && <PriceItem label="Kode Unik" value={uniqCode} />}
    <PriceItem label="Total" value={total} />
  </div>
);

PriceInfo.propTypes = {
  subtotal: PropTypes.number.isRequired,
  uniqCode: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default PriceInfo;
