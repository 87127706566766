import React from 'react';
import PropTypes from 'prop-types';

import { toRupiah } from '../../../helpers';

const ProductInfo = ({ image, productName, price }) => (
  <div className="mt-4">
    <h6 className="pb-1">DETAIL PESANAN</h6>
    <div className="card">
      <div className="card-body" style={{ padding: '20px 30px' }}>
        <div className="media">
          <a href={`${process.env.REACT_APP_SERVICE_URL_API}uploads/${image}`} target="_blank" rel="noreferrer">
            <img style={{ width: 60, height: 60, objectFit: 'cover' }} src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/${image}`} className="align-self-center mr-3 rounded" alt="thumb" />
          </a>
          <div className="media-body">
            <p className="mb-2">{productName}</p>
            <h5 className="text-danger">{toRupiah(price)}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ProductInfo.propTypes = {
  image: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default ProductInfo;
