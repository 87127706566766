import React from 'react';
import PropTypes from 'prop-types';

import { toRupiah } from '../../../helpers';

const TableRow = ({ label, value }) => (
  <tr>
    <td style={{ width: 150 }}>{label}</td>
    <td>{`: ${value}`}</td>
  </tr>
);

TableRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const TableInfo = ({ orderData }) => (
  <div className="table-responsive">
    <table className="table table-sm table-borderless mb-2">
      <tbody>
        <TableRow label="Order ID" value={orderData.invoice_id} />
        <TableRow label="Produk" value={orderData.product_name} />
        <TableRow label="Nama" value={orderData.client_name} />
        <TableRow label="No WhatsApp" value={orderData.client_whatsapp} />
        <TableRow label="Email" value={orderData.client_email} />
        <TableRow label="Total Harga" value={toRupiah(orderData.total_bill)} />
      </tbody>
    </table>
  </div>
);

TableInfo.propTypes = {
  orderData: PropTypes.shape({
    invoice_id: PropTypes.string,
    product_name: PropTypes.string,
    client_name: PropTypes.string,
    client_whatsapp: PropTypes.string,
    client_email: PropTypes.string,
    total_bill: PropTypes.number,
  }).isRequired,
};

export default TableInfo;
