import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Status from './components/Status';
import TableInfo from './components/TableInfo';
import CountdownExpired from './components/CountdownExpired';
import Notes from './components/Notes';
import PaymentNumberInfo from './components/PaymentNumberInfo';
import orderEndpoint from '../../config/service/endpoint/order';
import PaymentGuide from './components/PaymentGuide';
import { ErrorMessage, HeaderPaper, LoadingAnimation } from '../../components';
import { STATUS_ORDER } from '../../helpers';
import { useAppContext } from '../../hooks';

const ThankYouPage = () => {
  const [isExpired, setExpired] = useState(false);
  const { setting, state } = useAppContext();
  const { id, invoice } = useParams();

  const { data, isLoading, error } = useQuery('order', () => orderEndpoint.getOrder({ id, invoice }), {
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
  });

  if (state.settingLoading || isLoading) return <LoadingAnimation />;

  if (state.settingError || error) return <ErrorMessage message={error.message || state.settingError} />;

  const { data: orderData } = data;

  return (
    <>
      <Helmet>
        <title>{`Thank You - ${setting.businessName}`}</title>
      </Helmet>
      <div className="account-pages pt-sm-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 mb-4">
              <HeaderPaper />
              <div className="mt-4 p-4 rounded bg-white">
                <Status status={orderData.status} isExpired={isExpired} paymentOption={orderData.payment_option} />
                {(orderData.status === STATUS_ORDER.EXPIRED || isExpired) && (
                  <Alert variant="warning">
                    Waktu pembayaran habis pesanan telah dibatalkan
                  </Alert>
                )}
                <h5>{`Halo, ${orderData.client_name}`}</h5>
                <p>Terima kasih banyak untuk pemesanannya, data sudah kami terima.</p>
                <TableInfo orderData={orderData} />
                {[STATUS_ORDER.UNPAID].includes(orderData.status) && !isExpired && (
                  <CountdownExpired
                    paymentExpiry={orderData.payment_expiry}
                    onComplete={() => setExpired(true)}
                  />
                )}
                {[STATUS_ORDER.NEW, STATUS_ORDER.UNPAID].includes(orderData.status) && !isExpired && (
                  <>
                    <PaymentNumberInfo
                      paymentType={orderData.payment_type}
                      paymentNumber={orderData.payment_number}
                      qrisUrl={orderData.qris_url}
                      paymentOption={orderData.payment_option}
                    />
                    <PaymentGuide paymentType={orderData.payment_type} />
                  </>
                )}
                <Notes paymentOption={orderData.payment_option} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouPage;
