import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import { PAYMENT_OPTIONS, STATUS_ORDER } from '../../../helpers';
import { successLottie, waitingLottie } from '../../../assets';

const Status = ({ status, isExpired, paymentOption }) => {
  if (![STATUS_ORDER.NEW, STATUS_ORDER.UNPAID, STATUS_ORDER.PAID, STATUS_ORDER.COMPLETED].includes(status) || isExpired) return null;

  let animationData;
  let animationSize;
  let title;
  let description;

  if (paymentOption === PAYMENT_OPTIONS.MANUAL) return null;

  if ([STATUS_ORDER.NEW, STATUS_ORDER.UNPAID].includes(status)) {
    animationData = waitingLottie;
    animationSize = 150;
    title = 'Menunggu Pembayaran';
    description = (
      <>
        Silakan lakukan pembayaran sesuai
        <br />
        dengan panduan yang kami berikan dibawah.
      </>
    );
  } else if (status === STATUS_ORDER.PAID) {
    animationData = successLottie;
    animationSize = 110;
    title = 'Pembayaran Berhasil';
    description = (
      <>
        Kami sudah menerima pembayaran Anda,
        <br />
        silakan cek email untuk informasi lebih lanjut.
      </>
    );
  } else if (status === STATUS_ORDER.COMPLETED) {
    animationData = successLottie;
    animationSize = 110;
    title = 'Pesanan Selesai';
    description = (
      <>
        Terima kasih sudah membeli produk kami,
        <br />
        silakan cek email untuk informasi lebih lanjut.
      </>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          style={{ height: animationSize }}
        />
        <h5>{title}</h5>
        <p className="text-center text-secondary mb-0">
          {description}
        </p>
      </div>
      <hr />
    </>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  paymentOption: PropTypes.string.isRequired,
};

export default Status;
