import React from 'react';
import PropTypes from 'prop-types';

import { InputField } from '../../../components';

const FormOrder = ({
  values, errors, touched, isReOrder, handleBlur, handleChange,
}) => (
  <>
    <InputField
      id="name"
      name="name"
      label="Nama"
      placeholder="Nama Lengkap"
      readOnly={isReOrder}
      error={touched.name && errors.name}
      value={values.name}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <InputField
      id="whatsapp"
      name="whatsapp"
      label="No WhatsApp"
      placeholder="No WhatsApp Aktif"
      readOnly={isReOrder}
      error={touched.whatsapp && errors.whatsapp}
      value={values.whatsapp}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <InputField
      id="email"
      name="email"
      label="Email"
      placeholder="Masukkan Email"
      readOnly={isReOrder}
      error={touched.email && errors.email}
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <InputField
      id="password"
      name="password"
      label="Password"
      placeholder="Buat Password"
      type={isReOrder ? 'password' : 'text'}
      readOnly={isReOrder}
      error={touched.password && errors.password}
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  </>
);

FormOrder.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    whatsapp: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    whatsapp: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    name: PropTypes.bool,
    whatsapp: PropTypes.bool,
    email: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
  isReOrder: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default FormOrder;
