import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

import { PAYMENT_METHOD_LIST, PAYMENT_OPTIONS, TRANSFER_BANK_LIST } from '../../../helpers';
import { otherBankImage } from '../../../assets';
import { useAppContext } from '../../../hooks';

const PaymentContent = ({ number }) => (
  <div className="d-flex align-items-center">
    <p className="font-weight-bold mb-0">{number}</p>
    <CopyToClipboard text={number} onCopy={() => toast.success('Berhasil menyalin kode')}>
      <button aria-label="copy" type="button" className="btn p-0 btn-lg ml-2 copy-clipboard-button">
        <i className="uil-copy" />
      </button>
    </CopyToClipboard>
  </div>
);

PaymentContent.propTypes = {
  number: PropTypes.string,
};

PaymentContent.defaultProps = {
  number: '',
};

const Box = ({ children }) => (
  <div className="d-flex align-items-center justify-content-between p-3 rounded-lg" style={{ border: '1px solid #ddd' }}>
    {children}
  </div>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
};

const PaymentNumberInfo = ({
  paymentType, paymentNumber, qrisUrl, paymentOption,
}) => {
  const { setting } = useAppContext();

  const bankTransfer = ['bri_va', 'bni_va', 'other_va'];

  const paymentSelected = PAYMENT_METHOD_LIST.find((payment) => payment.code === paymentType);

  if (paymentOption === PAYMENT_OPTIONS.MANUAL) {
    const bankTransferSelected = TRANSFER_BANK_LIST.find((payment) => payment.code === setting.bankTransferBankName);
    return (
      <Box>
        <img height="25px" src={bankTransferSelected ? bankTransferSelected.image : otherBankImage} alt="logo bca" />
        <p className="mb-0 ml-2">{`a.n ${setting.bankTransferAccountName}`}</p>
        <PaymentContent number={setting.bankTransferAccountNumber} />
      </Box>
    );
  }

  if (!paymentSelected) return null;

  let infoRender = null;

  if (bankTransfer.includes(paymentType)) {
    infoRender = (
      <>
        <div className="d-flex align-items-center">
          <img height="20px" src={paymentSelected.image} alt={`logo ${paymentSelected.name}`} />
          <p className="mb-0 ml-2">{paymentSelected.name}</p>
        </div>
        <PaymentContent number={paymentNumber} />
      </>
    );
  } else if (paymentType === 'echannel') {
    const splitCode = paymentNumber.split('-');
    infoRender = (
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-center align-items-center">
          <img height="20px" src={paymentSelected.image} alt={`logo ${paymentSelected.name}`} />
          <p className="mb-0 ml-2">{paymentSelected.name}</p>
        </div>
        <div className="d-flex align-items-center mt-4">
          <p style={{ width: 130 }} className="mb-0">Kode Perusahaan</p>
          <PaymentContent number={splitCode[0]} />
        </div>
        <div className="d-flex align-items-center mt-1">
          <p style={{ width: 130 }} className="mb-0">Nomor VA</p>
          <PaymentContent number={splitCode[1]} />
        </div>
      </div>
    );
  } else if (paymentType === 'gopay') {
    infoRender = (
      <div className="d-flex flex-column align-items-center">
        <h5 style={{ fontSize: 16 }} className="mb-0 text-center">Scan QR Code dibawah ini</h5>
        <img src={qrisUrl} alt="qr code" style={{ maxWidth: 300 }} />
        <p className="text-center mb-0">Gunakan aplikasi seperti Gopay, Dana, LinkAja, ShopeePay, OVO, BCA Mobile, dan lain-lain.</p>
      </div>
    );
  }

  return <Box>{infoRender}</Box>;
};

PaymentNumberInfo.propTypes = {
  paymentType: PropTypes.string.isRequired,
  paymentNumber: PropTypes.string.isRequired,
  qrisUrl: PropTypes.string.isRequired,
  paymentOption: PropTypes.string.isRequired,
};

export default PaymentNumberInfo;
