import React from 'react';

const logoUrl = `${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`;

const HeaderPaper = () => (
  <div className="d-flex justify-content-center">
    <img className="logo-img" src={logoUrl} alt="logo" width="180" />
  </div>
);

export default HeaderPaper;
