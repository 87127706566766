import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from 'react-bootstrap-icons';

import { PAYMENT_METHOD_LIST } from '../../../helpers';

const PaymentMethod = ({ selected, error, onChange }) => (
  <>
    <div className="d-flex justify-content-between mt-5 pb-1">
      <h6>METODE PEMBAYARAN</h6>
    </div>
    <div className={`card ${error ? 'border border-danger' : ''}`}>
      <div className="card-body P-4">
        {error && <small className="text-danger d-block mb-2">{error}</small>}
        {PAYMENT_METHOD_LIST.map((payment) => (
          <div
            key={payment.code}
            className={`payment-item-wrapper mb-12 ${selected === payment.code ? 'border border-primary' : ''}`}
            onClick={() => onChange(payment.code)}
            onKeyDown={() => onChange(payment.code)}
            role="button"
            tabIndex={0}
          >
            <div className="payment-item-left">
              <img width="50px" src={payment.image} alt={payment.name} />
              <div className="ml-3">
                <p className="mb-0">{payment.name}</p>
                <small>{payment.description}</small>
              </div>
            </div>
            {selected === payment.code && (
              <CheckCircle size={20} color="#5b73e8" />
            )}
          </div>
        ))}
      </div>
    </div>
  </>
);

PaymentMethod.propTypes = {
  selected: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PaymentMethod.defaultProps = {
  error: '',
};

export default PaymentMethod;
