import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import {
  CheckoutPage, Error404, HomePage, ThankYouPage,
} from '../../views';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/:slug',
    element: <CheckoutPage />,
  },
  {
    path: '/thank-you/:id/:invoice',
    element: <ThankYouPage />,
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

export default router;
