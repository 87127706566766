import React from 'react';
import PropTypes from 'prop-types';

import { error404Image } from '../../assets';

const ErrorMessage = ({ message }) => (
  <div className="my-5 pt-sm-5">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center">
            <div>
              <div className="row justify-content-center">
                <div className="col-sm-4">
                  <div className="error-img">
                    <img src={error404Image} alt="404 error" className="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
            </div>
            <h4 className="text-uppercase mt-4">terjadi kesalahan.</h4>
            <p className="text-muted">{message}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
